

const allQuestions = [

    {
        "question": "Power Rangers was banned in what country until 2011?",
        "answer": "New Zealand",
        "wrongAnswers": ["South Africa","Costa Rica","Canada"]
    },
    {
        "question": "Who played Spider-Man in 2002 ?",
        "answer": "Toby Maguire",
        "wrongAnswers": ["Tom Holland","Andrew Garfield","Ned Sugar"]
    },
    {
        "question": "Which noughties sitcom revolves around a group of people working in a paper supply company?",
        "answer": "The Office",
        "wrongAnswers": ["The Paper Company","The IT Crowd","Corporate"]
    },
    {
        "question": "In 'The Simpsons' family, who is a vegetarian?",
        "answer": "Lisa Simpson",
        "wrongAnswers": ["Mona Simpson","Maggie Simpson","Homer Simpson"]
    },
    {
        "question": "In the video game released in 2009 named Angary Birds, what color are the pigs in this game?",
        "answer": "Green",
        "wrongAnswers": ["Pink","White","Red"]
    },
    {
        "question": "What is the somewhat-logical term for the internet prank and meme in which an unexpected appearance of the music video for the 1987 Rick Astley song \"Never Gonna Give You Up\" is \"planted\" into an unrelated link? The meme's popularity dates back to 2008.",
        "answer": "rickrolling",
        "wrongAnswers": ["slimrolling","frogrolling","duckrolling"]
    },
    {
        "question": "Meredith and Olivia are two of the cats owned by what popular singer born in 1989? The pets are named after leading ladies from Grey's Anatomy and Law & Order: SVU.",
        "answer": "Taylor Swift",
        "wrongAnswers": ["Ed Sheeran","Johnny Cash","Lady Gaga"]
    },
    {
        "question": "Moto Moto is an animated hippo known for his flirtatious attitude and also for an extremely popular meme of Moto Moto exiting a pond. From what movie franchise is Moto Moto from?",
        "answer": "Madagascar",
        "wrongAnswers": ["Rio","Ice Age","Zootopia"]
    },
    {
        "question": "In the 2005 remake of \"Charlie and the Chocolate Factory\" starring Johnny Depp, the clandestine candy creator's father worked in what occupation? Hint: some may call the occupation ironic, some may call it coincidental.",
        "answer": "Dentist",
        "wrongAnswers": ["Barber","Gardener","Software Enginner"]
    },
    {
        "question": "Bill Gates appears on the cover of a May 2005 Time Magazine issue. He is holding something in his hand, and the subtitle reads: \"Microsoft has finally made something hip.",
        "answer": "Xbox 360",
        "wrongAnswers": ["Surface","HoloLens","Azure Kinect"]
    },
    {
        "question": "In the move Elf, the elves' diet consists of the 4 main food groups: candy, candy cane, candy corn, and _____.",
        "answer": "Syrup",
        "wrongAnswers": ["Sugar","Chocolate","Cookies"]
    },
    {
        "question": "In the TV show Family Guy, Lois Griffin teaches what musical instrument?",
        "answer": "Piano",
        "wrongAnswers": ["Violin","Bass","Saxophone"]
    },
    {
        "question": "What is the name for the evil organization in The Force Awakens?",
        "answer": "The First Order",
        "wrongAnswers": ["The Revengers","The Really Bad Guys","The Empire"]
    },
    {
        "question": "What is the name of Tim Taylor's neighbor?",
        "answer": "Wilson",
        "wrongAnswers": ["John","Sam","Ned"]
    },
    {
        "question": "Who played the Joker in the 2008 movie, The Dark Night?",
        "answer": "Heath Ledger",
        "wrongAnswers": ["Mark Hamill","Tom Hardy","Jack Nicholson"]
    },
    {
        "question": "Frank Reynolds is a character on what TV show based in Pennsylvania?",
        "answer": "It's Always Sunny in Philadelphia",
        "wrongAnswers": ["The Office","Boy Meets World","Mare of Easttown"]
    },
    {
        "question": "Which former girl-group member sings Havana?",
        "answer": "Camilla Cabello",
        "wrongAnswers": ["Sporty Spice","Justin Timberlake","Normani"]
    },
    {
        "question": "What is the name of Chuck Wilson's ball in Castaway?",
        "answer": "Wilson",
        "wrongAnswers": ["Spalding","Franklin","Russel"]
    },
    {
        "question": "What does Rose use as a raft to escape the sinking Titanic? Tragically, there was not enough room for Jack.",
        "answer": "Door",
        "wrongAnswers": ["Very small life boat","Bed","Chandelier"]
    },
    {
        "question": "What animal does Indiana Jones specifically hate?",
        "answer": "Snakes",
        "wrongAnswers": ["Monkeys","Sharks","Cats"]
    },
    {
        "question": "According to 2000 movie Miss Congeniality, what is the perfect date?",
        "answer": "April 25th",
        "wrongAnswers": ["Long walk on the beach","Coffee shop","Romantic dinner"]
    },
    {
        "question": "On the Mandalorian, what is Baby Yoda's real name?",
        "answer": "Grogu",
        "wrongAnswers": ["Baby Yoda","The Child","Rey"]
    },
    {
        "question": "What mode of transportation is the Polar Express?",
        "answer": "train",
        "wrongAnswers": ["plane","bus","ship"]
    },
    {
        "question": "What gas is used to \"turbo\" cars in The Fast and the Furious",
        "answer": "nitrous",
        "wrongAnswers": ["helium","ethanol","methane"]
    },
    {
        "question": "If you were to watch Marvel movies in chronological order, which Avenger's movie would you watch first?",
        "answer": "Captain America",
        "wrongAnswers": ["Thor","Iron Man","Thanos"]
    },
    {
        "question": "Which Disney princess was originally intended to be a villain?",
        "answer": "Elsa",
        "wrongAnswers": ["Anastasia","Ariel","Giselle"]
    }

];

export default allQuestions;
