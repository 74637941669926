import ned from '../imgs/ned-wrong.png';
import '../App.css';

function Home() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={ned} className="Ned-img" alt="Ned" />
                <p>
                    Better luck next time! Not everyone can be as good at trivia as Ned :(
                </p>
                <a
                    className="App-link"
                    href="/trivia/random"
                >
                    Would you like to try again on another question?
                </a>
            </header>
        </div>
    );
}

export default Home;