import ned from '../imgs/ned-success.png';
import '../App.css';

function Home() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={ned} className="Ned-img" alt="Ned" />
                <p>
                    Great job! You've succeeded in your challenge!
                </p>
                <a
                    className="App-link"
                    href="/trivia/random"
                >
                    Would you like to try another question?
                </a>
            </header>
        </div>
    );
}

export default Home;