import logo from '../imgs/ned-home.png';
import '../App.css';

function Home() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Welcome to the Ned Cramer Challenge Question.
        </p>
        <a
          className="App-link"
          href="/trivia"
        >
          Answer the question!
        </a>
      </header>
    </div>
  );
}

export default Home;
