import ned from '../imgs/ned-question.png';
import '../App.css';
import allQuestions from './AllQuestions';
import React from "react";

function Question(random) {

    // TODO: This block supports an API backend for questions instead of a static list
    // const [question, setQuestion] = React.useState(null);
    //
    // React.useEffect(() => {
    //     fetch("http://localhost:8080/trivia/random")
    //         .then(response => response.json() )
    //         .then(data => {
    //             setQuestion(data)
    //         })
    //         .catch(error => console.log(error));
    // }, [])

    let index = 0;
    if (random) {
        index = Math.floor(Math.random() * allQuestions.length);
    } else {
        // TODO: get today's index
    }
    const question = allQuestions[index];

    const correct = (<a href="/trivia/correct"  className="Answer-link">{question?.answer}</a>);
    const wrong = question?.wrongAnswers.map(w => (<a href="/trivia/wrong" className="Answer-link">{w}</a>));
    const allAnswers = !question ? [] : [correct, ...wrong];
    const shuffled = shuffle(allAnswers);
    return (
        <div className="App">
            <header className="App-header">
                <img src={ned} className="Ned-img" alt="Ned" />
                <p>
                    {question?.question}
                </p>
                <ol>

                    {shuffled?.map(answer => <li>{answer}</li>)}

                </ol>
            </header>
        </div>
    );
}

function shuffle(original) {
    let array = [...original];
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
    }
    return array;
}

export default Question;
