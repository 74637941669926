import { Routes, Route} from "react-router-dom";

import Home from "./routes/Home";
import Question from "./routes/Question";
import Correct from "./routes/Correct";
import Wrong from "./routes/Wrong";

import './App.css';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={ <Home />} />
        <Route path="/trivia" element={ <Question /> } />
        <Route path="/trivia/random" element={ <Question random={true} /> } />
        <Route path="/trivia/correct" element={ <Correct /> } />
        <Route path="/trivia/wrong" element={ <Wrong /> } />
      </Routes>
    </div>
  );
}


export default App;
